import React, { Component } from "react";
import "../App.css";
import { Animated } from "react-animated-css";

import NavBar from "./NavBar";
import Questions from "./Questions";
import Steppers from "./Steppers";

class Website extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      discount: "",
      location: "",
      data: ""
    };
  }

  myCallbackPage = dataFromChild => {
    this.setState({
      page: dataFromChild
    });
  };
  myCallbackD = dataFromChild => {
    this.setState({
      discount: dataFromChild
    });
  };
  myCallbackLocation = dataFromChild => {
    this.setState({
      location: dataFromChild
    });
  };
  myCallbackData = dataFromChild => {
    this.setState({
      data: dataFromChild
    });
  };

  render() {
    return (
      <Animated animationIn="fadeIn" animationInDelay="300" isVisible={true}>
        <div className="grid-container">
          <NavBar
            callbackFromParentPage={this.myCallbackPage}
            currentState={this.state}
          />
          <Questions
            callbackFromParentD={this.myCallbackD}
            callbackFromParentPage={this.myCallbackPage}
            callbackFromParentUpdateData={this.myCallbackData}
            callbackFromParentUpdateLocation={this.myCallbackLocation}
            currentState={this.state}
          />

          <Steppers currentState={this.state} />
        </div>
      </Animated>
    );
  }
}

export default Website;
