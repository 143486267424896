import React, { Component } from "react";
import "../App.css";
import { Animated } from "react-animated-css";

let color1 = "";
let color2 = "";
let color3 = "";

class Steppers extends Component {
  render() {
    if (this.props.currentState.page === 2) {
      color1 = "orange";
      color2 = "gray";
      color3 = "gray";
    } else if (this.props.currentState.page === 4) {
      color1 = "#93d17d";
      color2 = "orange";
      color3 = "gray";
    } else if (this.props.currentState.page === 5) {
      color1 = "#93d17d";
      color2 = "#93d17d";
      color3 = "orange";
    } else if (this.props.currentState.page === 8) {
      color1 = "#93d17d";
      color2 = "#93d17d";
      color3 = "#93d17d";
    }
    if (this.props.currentState.page >= 2) {
      return (
        <div className="grid-item" id="steppers">
          <Animated
            animationIn="fadeInLeft"
            animationInDelay="700"
            isVisible={true}
          >
            <span
              style={{ backgroundColor: color1 }}
              id="student-dot"
              className="dot"
            />
            <p id="stepper">
              Rabatt: <b>{this.props.currentState.discount}</b>
            </p>
            <br />
            <span
              style={{ backgroundColor: color2 }}
              id="location-dot"
              className="dot"
            />
            <p id="stepper">
              Plats: <b>{this.props.currentState.location}</b>
            </p>
            <br />
            <span
              style={{ backgroundColor: color3 }}
              id="data-dot"
              className="dot"
            />
            <p id="stepper">
              Surf: <b>{this.props.currentState.data}</b> GB
            </p>
            <br />
          </Animated>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Steppers;
