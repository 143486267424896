import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: [
      "Upplands Väsby",
      "Vallentuna",
      "Österåker",
      "Värmdö",
      "Järfälla",
      "Ekerö",
      "Huddinge",
      "Botkyrka",
      "Salem",
      "Haninge",
      "Tyresö",
      "Upplands-Bro",
      "Nykvarn",
      "Täby",
      "Danderyd",
      "Sollentuna",
      "Stockholm",
      "Södertälje",
      "Nacka",
      "Sundbyberg",
      "Solna",
      "Lidingö",
      "Vaxholm",
      "Norrtälje",
      "Sigtuna",
      "Nynäshamn",
      "Håbo",
      "Älvkarleby",
      "Knivsta",
      "Heby",
      "Tierp",
      "Uppsala",
      "Enköping",
      "Östhammar",
      "Vingåker",
      "Gnesta",
      "Nyköping",
      "Oxelösund",
      "Flens",
      "Katrineholm",
      "Eskilstuna",
      "Strängnä",
      "Trosa",
      "Ödeshög",
      "Ydre",
      "Kinda",
      "Boxholm",
      "Åtvidaberg",
      "Finspång",
      "Valdemarsvik",
      "Linköping",
      "Norrköping",
      "Söderköping",
      "Motala",
      "Vadstena",
      "Mjölby",
      "Aneby",
      "Gnosjö",
      "Mullsjö",
      "Habo",
      "Gislaved",
      "Vaggeryd",
      "Jönköping",
      "Nässjö",
      "Värnamo",
      "Sävsjö",
      "Vetlanda",
      "Eksjö",
      "Tranå",
      "Uppvidinge",
      "Lessebo",
      "Tingsryd",
      "Alvesta",
      "Älmhult",
      "Markaryd",
      "Växjö",
      "Ljungby",
      "Högsby",
      "Torså",
      "Mörbylånga",
      "Hultsfred",
      "Mönsterå",
      "Emmaboda",
      "Kalmar",
      "Nybro",
      "Oskarshamn",
      "Västervik",
      "Vimmerby",
      "Borgholm",
      "Gotland",
      "Olofström",
      "Karlskrona",
      "Ronneby",
      "Karlshamn",
      "Sölvesborg",
      "Svalöv",
      "Staffanstorp",
      "Burlöv",
      "Vellinge",
      "Östra Göinge",
      "Örkelljunga",
      "Bjuv",
      "Kävlinge",
      "Lomma",
      "Svedala",
      "Skurup",
      "Sjöbo",
      "Hörby",
      "Höörs",
      "Tomelilla",
      "Bromölla",
      "Osby",
      "Perstorp",
      "Klippans",
      "Åstorps",
      "Båstads",
      "Malmö",
      "Lunds",
      "Landskrona",
      "Helsingborgs",
      "Höganäs",
      "Eslövs",
      "Ystads",
      "Trelleborgs",
      "Kristianstads",
      "Simrishamns",
      "Ängelholms",
      "Hässleholms",
      "Hylte",
      "Halmstads",
      "Laholms",
      "Falkenbergs",
      "Varbergs",
      "Kungsbacka",
      "Härryda",
      "Partille",
      "Öckerö",
      "Stenungsunds",
      "Tjörns",
      "Orusts",
      "Sotenäs",
      "Munkedals",
      "Tanums",
      "Dals-Eds",
      "Färgelanda",
      "Ale",
      "Lerums",
      "Vårgårda",
      "Bollebygds",
      "Grästorps",
      "Essunga",
      "Karlsborgs",
      "Gullspångs",
      "Tranemo",
      "Bengtsfors",
      "Melleruds",
      "Lilla Edets",
      "Marks",
      "Svenljunga",
      "Herrljunga",
      "Vara",
      "Götene",
      "Tibro",
      "Töreboda",
      "Göteborgs",
      "Mölndals",
      "Kungälvs",
      "Lysekils",
      "Uddevalla",
      "Strömstads",
      "Vänersborgs",
      "Trollhättans",
      "Alingsås",
      "Borås",
      "Ulricehamns",
      "Åmåls",
      "Mariestads",
      "Lidköpings",
      "Skara",
      "Skövde",
      "Hjo",
      "Tidaholms",
      "Falköpings",
      "Kils",
      "Eda",
      "Torsby",
      "Storfors",
      "Hammarö",
      "Munkfors",
      "Forshaga",
      "Grums",
      "Årjängs",
      "Sunne",
      "Karlstads",
      "Kristinehamns",
      "Filipstads",
      "Hagfors",
      "Arvika",
      "Säffle",
      "Lekebergs",
      "Laxå",
      "Hallsbergs",
      "Degerfors",
      "Hällefors",
      "Ljusnarsbergs",
      "Örebro",
      "Kumla",
      "Askersunds",
      "Karlskoga",
      "Nora",
      "Lindesberg",
      "Skinnskatteberg",
      "Surahammar",
      "Kungsör",
      "Hallstahammar",
      "Norberg",
      "Västerås",
      "Sala",
      "Fagersta",
      "Köping",
      "Arboga",
      "Vansbro",
      "Malung-Sälen",
      "Gagnefs",
      "Leksands",
      "Rättvik",
      "Orsa",
      "Älvdalen",
      "Smedjebacken",
      "Mora",
      "Falu",
      "Borlänge",
      "Säters",
      "Hedemora",
      "Avesta",
      "Ludvika",
      "Ockelbo",
      "Hofors",
      "Ovanåkers",
      "Nordanstigs",
      "Ljusdals",
      "Gävle",
      "Sandvikens",
      "Söderhamns",
      "Bollnäs",
      "Hudiksvalls",
      "Ånge",
      "Timrå",
      "Härnösands",
      "Sundsvalls",
      "Kramfors",
      "Sollefteå",
      "Örnsköldsviks",
      "Ragunda",
      "Bräcke",
      "Krokoms",
      "Strömsunds",
      "Åre",
      "Bergs",
      "Härjedalens",
      "Östersunds",
      "Nordmalings",
      "Bjurholms",
      "Vindelns",
      "Robertsfors",
      "Norsjö",
      "Malå",
      "Storumans",
      "Sorsele",
      "Dorotea",
      "Vännäs",
      "Vilhelmina",
      "Åsele",
      "Umeå",
      "Lycksele",
      "Skellefteå",
      "Arvidsjaurs",
      "Arjeplogs",
      "Jokkmokks",
      "Överkalix",
      "Kalix",
      "Övertorneå",
      "Pajala",
      "Gällivare",
      "Älvsbyns",
      "Luleå",
      "Piteå",
      "Bodens",
      "Haparanda",
      "Kiruna"
    ]
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });

    this.props.callbackFromParentUpdateLocation(e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    this.props.callbackFromParentUpdateLocation(e.currentTarget.innerText);
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
      this.props.callbackFromParentUpdateLocation(
        filteredSuggestions[activeSuggestion]
      );
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          id="inputfield"
          placeholder="Norrköping..."
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
