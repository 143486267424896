import React, { Component } from "react";
import "../App.css";

class NavBar extends Component {
  handleClick = value => event => {
    this.props.callbackFromParentPage(value);
  };
  render() {
    return (
      <div className="grid-item" id="navbar">
        <ul>
          <li onClick={this.handleClick(1)}>Hem</li>
          <li onClick={this.handleClick(-1)}>Mer Info</li>
          <li onClick={this.handleClick(8)}>Resultat</li>
        </ul>
      </div>
    );
  }
}

export default NavBar;
