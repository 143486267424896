import React, { Component } from "react";
import "../App.css";
import { Animated } from "react-animated-css";
import Autocomplete from "./Autocomplete";

class Questions extends Component {
  handleDiscount = value => event => {
    this.props.callbackFromParentD("Ja");
    this.props.callbackFromParentPage(value);
  };
  handleNotDiscount = value => event => {
    this.props.callbackFromParentD("Nej");
    this.props.callbackFromParentPage(value);
  };
  handleClick = value => event => {
    this.props.callbackFromParentPage(value);
  };
  handleClickDataForm = value => event => {
    if (!this.props.currentState.data) {
      this.props.callbackFromParentUpdateData(8);
    }
    this.props.callbackFromParentPage(value);
  };
  updateData = event => {
    this.props.callbackFromParentUpdateData(event.target.value);
  };
  updateLocation = event => {
    this.props.callbackFromParentUpdateLocation(event.target.value);
  };

  displayProps = event => {
    console.log(this.props.currentState);
  };

  myCallbackLocation = dataFromChild => {
    this.props.callbackFromParentUpdateLocation(dataFromChild);
  };
  buy = event => {
    console.log(Math.round(this.props.currentState.data));
  };
  render() {
    if (this.props.currentState.page === -1) {
      return (
        <div className="grid-item" id="questions">
          <Animated
            animationIn="fadeInDown"
            animationInDelay="500"
            isVisible={true}
          >
            <hr />
          </Animated>
          <h1 id="title">Operatörskollen</h1>

          <p id="info-text">
            Genom att ställa frågor om din ålder, plats, och datamängdsvanor kan
            vi hitta det bästa abonnemanget för dig. Vår prioritet är att hitta
            ett billigt abonnemang med god täckning i ditt område. Finns det
            eventuella rabatter du kan nyttja hittar vi även dessa åt dig.
            <br />
            <br />
            Vi sparar ingen information om dig när du lämnar sidan.
          </p>

          <button id="yes" onClick={this.handleClick(2)}>
            Börja
          </button>
          <p>
            Operatörer:
            <ul>
              <li>Telia</li>
              <li>Halebop</li>
              <li>Tele2</li>
              <li>Telenor</li>
              <li>3</li>
              <li>Hallon</li>
              <li>Vimla</li>
              <li>Comviq</li>
            </ul>
          </p>
          <br />
          <p>
            Skapat av:
            <ul>
              <li>Fredrik</li>
              <li>Linus</li>
              <li>Daniel</li>
              <li>Daniel</li>
              <li>Ludde</li>
              <li>Josef</li>
            </ul>
          </p>
          <br />
          <Animated
            animationIn="fadeInUp"
            animationInDelay="500"
            isVisible={true}
          >
            <hr />
          </Animated>
        </div>
      );
    } else if (this.props.currentState.page === 1) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Operatörskollen</h1>
          <h2 id="sub-title">Hitta det bästa abonnemanget för dig</h2>
          <Animated
            animationIn="flash"
            animationInDelay="3000"
            isVisible={true}
          >
            <button id="yes" onClick={this.handleClick(2)}>
              Börja
            </button>
          </Animated>
          <p id="info-text">
            Genom att göra vår analys kan vi hitta det bästa mobilabonnemanget
            för dig. Börja genom att klicka på knappen ovan.
          </p>
          <button id="back" onClick={this.handleClick(-1)}>
            Mer info
          </button>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 2) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Är du student eller pensionär?</h1>
          <button id="other-small" onClick={this.handleDiscount(4)}>
            Student
          </button>
          <button id="other-small" onClick={this.handleDiscount(4)}>
            Pensionär
          </button>
          <button id="no" onClick={this.handleClick(3)}>
            Nej
          </button>

          <br />
          <button id="back" onClick={this.handleClick(1)}>
            Tillbaka
          </button>
          <p id="info-text">
            Alternativen används för att bestämma eventuella rabatter eller
            prisskillnader
          </p>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 3) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Är du under 27 år gammal?</h1>
          <button id="yes" onClick={this.handleDiscount(4)}>
            Ja
          </button>
          <button id="no" onClick={this.handleNotDiscount(4)}>
            Nej
          </button>
          <br />
          <button id="back" onClick={this.handleClick(2)}>
            Tillbaka
          </button>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 4) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 className="text-input" id="title">
            Vart bor du?
          </h1>
          <Autocomplete
            callbackFromParentUpdateLocation={this.myCallbackLocation}
          />

          <br />
          <button id="back" onClick={this.handleClick(2)}>
            Tillbaka
          </button>
          <button id="next" onClick={this.handleClick(5)}>
            Nästa
          </button>
          <p id="info-text">
            Din plats används för att avgöra vilken operatör som har bäst
            täckning i ditt område.
          </p>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 5) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Hur mycket data behöver du?</h1>
          <p id="info-text">
            Om du är osäker på vad data är eller hur mycket av det du använder,
            klicka på ‘Jag vet inte’
          </p>

          <button id="other" onClick={this.handleClick(7)}>
            Jag vet inte
          </button>
          <button id="other" onClick={this.handleClick(6)}>
            Jag vill bestämma exakt
          </button>
          <br />
          <button id="back" onClick={this.handleClick(4)}>
            Tillbaka
          </button>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 6) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Data</h1>
          <p id="info-text">Fyll i med siffror hur många GB data du behöver.</p>
          <input
            className="text-input"
            id="data-input"
            type="number"
            value={this.props.currentState.data}
            onChange={this.updateData}
            onKeyPress={event => {
              if (event.key === "Enter") {
                console.log("Pressed", event.key);
                this.props.callbackFromParentPage(8);
              }
            }}
          />
          <br />
          <button id="back" onClick={this.handleClick(5)}>
            Tillbaka
          </button>
          <button id="next" onClick={this.handleClick(8)}>
            Nästa
          </button>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 7) {
      return (
        <div className="grid-item" id="questions">
          <hr />
          <h1 id="title">Surfvanor</h1>
          <p id="q-subtitle">
            Fyll i alternativen för att göra en upskattning av din
            dataförbrukning.
          </p>
          <p>
            <b>Timmar per dag. 1-5h. Vänster till höger.</b>
          </p>
          <p id="q-titel">Vanlig surf:</p>
          <p id="q-subtitel">T.ex. Internet, tidning, mail</p>
          <form className="radio-form">
            <input className="radio-input" type="radio" name="1" value="1" />
            0-1
            <input className="radio-input" type="radio" name="1" value="2" />
            2
            <input className="radio-input" type="radio" name="1" value="3" />
            3
            <input className="radio-input" type="radio" name="1" value="4" />
            4
            <input className="radio-input" type="radio" name="1" value="5" />5
          </form>

          <p id="q-titel">Sociala medier:</p>
          <p id="q-subtitel">T.ex. Snapchat, Facebook, Instagram</p>
          <form className="radio-form">
            <input className="radio-input" type="radio" name="1" value="1" />
            0-1
            <input className="radio-input" type="radio" name="1" value="2" />
            2
            <input className="radio-input" type="radio" name="1" value="3" />
            3
            <input className="radio-input" type="radio" name="1" value="4" />
            4
            <input className="radio-input" type="radio" name="1" value="5" />5
          </form>
          <p id="q-titel">Musik:</p>
          <p id="q-subtitel">T.ex. Spotify, Apple Music, Deezer</p>
          <form className="radio-form">
            <input className="radio-input" type="radio" name="1" value="1" />
            0-1
            <input className="radio-input" type="radio" name="1" value="2" />
            2
            <input className="radio-input" type="radio" name="1" value="3" />
            3
            <input className="radio-input" type="radio" name="1" value="4" />
            4
            <input className="radio-input" type="radio" name="1" value="5" />5
          </form>
          <p id="q-titel">Film:</p>
          <p id="q-subtitel">T.ex. Netflix, Youtube, SVT Play</p>
          <form className="radio-form">
            <input className="radio-input" type="radio" name="1" value="1" />
            0-1
            <input className="radio-input" type="radio" name="1" value="2" />
            2
            <input className="radio-input" type="radio" name="1" value="3" />
            3
            <input className="radio-input" type="radio" name="1" value="4" />
            4
            <input className="radio-input" type="radio" name="1" value="5" />5
          </form>
          <br />
          <button id="back" onClick={this.handleClick(5)}>
            Tillbaka
          </button>
          <button id="next" onClick={this.handleClickDataForm(8)}>
            Nästa
          </button>
          <hr />
        </div>
      );
    } else if (this.props.currentState.page === 8) {
      if (this.props.currentState.location === "Norrköping") {
        return (
          <div className="grid-item" id="questions">
            <div className="card">
              <div id="left">
                <p id="best-billigast">
                  <b>Bäst</b>
                </p>
                <p id="result-data">
                  <b>8GB</b>
                </p>
                <p id="price">
                  <b>249kr/mån</b>
                </p>
                <p>Tele2</p>
                <div>
                  <i class="material-icons" id="icon-check">
                    check
                  </i>
                  Bra kundservice
                  <br />
                  <i class="material-icons" id="icon-check">
                    check
                  </i>
                  Ingen bindningstid
                  <br />
                  <i class="material-icons" id="icon-remove">
                    remove
                  </i>
                  Relativt bra täckning
                </div>
                <button id="buy">
                  <a
                    href="https://www.tele2.se/handla/mobilabonnemang"
                    target="_blank"
                  >
                    Köp
                  </a>
                </button>
              </div>
              <div id="right">
                <p id="best-billigast">
                  <b>Billigast</b>
                </p>
                <p id="result-data">
                  <b>8GB</b>
                </p>
                <p id="price">
                  <b>149kr/mån</b>
                </p>
                <p>Hallon</p>
                <div>
                  <i className="material-icons" id="icon-check">
                    check
                  </i>
                  Billigt
                  <br />
                  <i className="material-icons" id="icon-close">
                    close
                  </i>
                  Dålig kundservice
                  <br />
                  <i className="material-icons" id="icon-close">
                    close
                  </i>
                  Dålig täckning på landsbyggden
                </div>
                <button id="buy">
                  <a href="https://www.hallon.se/mobiltelefoni" target="_blank">
                    Köp
                  </a>
                </button>
              </div>
            </div>
            <button id="back" onClick={this.handleClick(5)}>
              Tillbaka
            </button>
            <button id="next" onClick={this.handleClick(1)}>
              Börja om
            </button>
          </div>
        );
      } else {
        return (
          <div className="grid-item" id="questions">
            <div className="card">
              <div id="left">
                <p id="best-billigast">
                  <b>Bäst</b>
                </p>
                <p id="result-data">
                  <b>40GB</b>
                </p>
                <p id="price">
                  <b>539kr/mån</b>
                </p>
                <p>Telia</p>
                <div>
                  <i class="material-icons" id="icon-check">
                    check
                  </i>
                  Bra täckning
                  <br />
                  <i class="material-icons" id="icon-check">
                    check
                  </i>
                  Bra kundservice
                  <br />
                  <i class="material-icons" id="icon-check">
                    check
                  </i>
                  Fri socialamedier surf
                  <br />
                  <i class="material-icons" id="icon-close">
                    close
                  </i>
                  Dyrt
                </div>
                <button id="buy">
                  <a
                    href="https://www.telia.se/privat/telefoni/abonnemang-kontantkort/produkt/mobilabonnemang?intcmp=kategoristart_abonnemang"
                    target="_blank"
                  >
                    Köp
                  </a>
                </button>
              </div>
              <div id="right">
                <p id="best-billigast">
                  <b>Billigast</b>
                </p>
                <p id="result-data">
                  <b>30GB</b>
                </p>
                <p id="price">
                  <b>369kr/mån</b>
                </p>
                <p>Halebop</p>
                <div>
                  <i className="material-icons" id="icon-check">
                    check
                  </i>
                  Bra täckning
                  <br />
                  <i className="material-icons" id="icon-check">
                    check
                  </i>
                  Billigt
                  <br />
                  <i className="material-icons" id="icon-close">
                    close
                  </i>
                  Ingen kundservice över telefon
                </div>
                <button id="buy">
                  <a
                    href="https://shop.halebop.se/mobilabonnemang"
                    target="_blank"
                  >
                    Köp
                  </a>
                </button>
              </div>
            </div>
            <button id="back" onClick={this.handleClick(5)}>
              Tillbaka
            </button>
            <button id="next" onClick={this.handleClick(1)}>
              Börja om
            </button>
          </div>
        );
      }
    }
  }
}

export default Questions;
